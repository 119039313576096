<template lang="pug">
el-drawer(
  title="开票"
  size="80%"
  :visible.sync="visible"
  modal
  append-to-body
  :before-close="handleClose"
  @open="handleDrawerOpen")
  .form-box(v-if="visible")
    label.blue-header 用水户（先搜索用水户再选择账单）
    .usewater-table-box
      DataTable.full-box(
        ref="usewaterTable"
        keyName="usewaterTable"
        defaultEmpty
        serverSide
        highlightClickRow
        :needFilterMargin="false"
        resource="/usewater/list"
        size="medium"
        :filterFields="usewaterFields"
        :columns="usewaterColumns"
        @current-change="handleUsewaterChange")
    .blue-header 选择用水户账单
    .table-box(:class="{'flex-center': !billExtraFilterBy.usewaterId}")
      DataTable.full-box(
        v-if="!!billExtraFilterBy.usewaterId"
        ref="billTable"
        keyName="billTable"
        serverSide
        isSelection
        resource="/bill/compreQuery"
        :filterFields="billFilterFields"
        :columns="columns"
        :extraFilterBy="billExtraFilterBy"
        :needFilterMargin="false"
        :showFilter="false"
        :defaultSortBy="[{prop: 'billCreateTime', order: 'desc'}]"
        @selectionChange="handleSelectionChange")
      .empty(v-else) 请先选择用水户
    .blue-header 填写票据信息
    DataForm(
      ref="form"
      v-model="formData"
      label-width="120px"
      type="row"
      :colspanNum="2"
      :formFields="formFields")
  .button-box.full-width
    ButtonGroup(
      :buttonList="buttonList")
</template>

<script>
import { mapActions } from 'vuex'
import regexs from '@/utils/regexs'

export default {
  name: 'InvoiceDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      showForm: false,
      formData: {},
      // 用水户
      currentUsewater: {},
      usewaterColumns: [
        { name: 'usewaterCode', label: '用水户号', width: 120 },
        {
          name: 'customerName',
          label: '用户',
          minWidth: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 200 }
      ],
      usewaterFields: [{
        name: 'keyword',
        label: '关键词',
        form: {
          tag: 'input',
          like: true,
          placeholder: '用水户号/用水户名/用户编号/用户姓名',
          inputWidth: '290px'
        }
      }],
      // 选择账单
      selectedData: [],
      billFilterFields: [{
        name: 'invoice',
        label: '是否开票',
        showable: false,
        form: {
          tag: 'input'
        }
      }, {
        name: 'billPayStatus',
        label: '缴费状态',
        showable: false,
        form: {
          tag: 'input'
        }
      }, {
        name: 'usewaterId',
        label: 'usewaterId',
        showable: false,
        form: {
          tag: 'input'
        }
      }],
      billExtraFilterBy: { invoice: false, billPayStatus: 'PAYED', usewaterId: null },
      // 表格列配置
      columns: [
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 100 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' },
        { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } }
      ],
      // 开票表单
      formFields: [{
        name: 'invoiceCode',
        label: '发票编号',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      }, {
        name: 'invoiceNo',
        label: '发票号码',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      }, {
        name: 'invoiceName',
        label: '开票名称',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      }, {
        name: 'invoiceBank',
        label: '开票银行',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      },
      {
        name: 'invoicePhone',
        label: '开票联系电话',
        form: {
          tag: 'input',
          rules: [regexs.required, regexs.mobileAndPhone]
        }
      },
      {
        name: 'invoiceTax',
        label: '纳税人识别号',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      },
      {
        name: 'invoiceBankAccount',
        label: '银行账号',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      }, {
        name: 'address',
        label: '联系地址',
        form: {
          tag: 'input',
          rules: [regexs.required]
        }
      }],
      buttonList: [{
        name: 'confirm',
        label: '保存',
        type: 'primary',
        func: this.handleSave
      }, {
        name: 'close',
        label: '关闭',
        func: this.handleClose
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    handleDrawerOpen () {
      this.$nextTick(() => {
        const formRef = this.$refs.form
        formRef && formRef.$refs.dataform.clearValidate()
      })
    },
    handleClose () {
      this.formData = {}
      this.selectedData = []
      this.billExtraFilterBy.usewaterId = null
      this.$emit('input', false)
    },
    handleUsewaterChange (row) {
      this.currentUsewater = row
      this.setDefaultInvoice(row.customerId)
      this.$set(this.billExtraFilterBy, 'usewaterId', row ? row.id : null)
      if (this.$refs.billTable) {
        this.$refs.billTable.clearSelection()
        this.$refs.billTable.reRenderTableData()
      }
    },
    handleSelectionChange (rows) {
      this.selectedData = rows
    },
    setDefaultInvoice (customerId) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/customer/get/${customerId}`
        })
          .then(customer => {
            if (!customer) {
              reject(customer)
              return
            }
            const nameList = ['invoiceName', 'invoiceBank', 'invoiceBankAccount', 'invoiceTax', 'invoicePhone', 'invoiceCode', 'invoiceNo', 'address']
            this.formData = nameList.reduce((res, name) => {
              res[name] = customer.data[name]
              return res
            }, { })
            resolve()
          })
          .catch(reject)
      })
    },
    async handleSave () {
      if (!this.selectedData.length) {
        this.$message.warning('请先选择开票账单')
        return
      }
      try {
        await this.$refs.form.$refs.dataform.validate()
        const billIds = this.selectedData.map(i => i.id)
        const res = await this.$post({
          url: '/invoice/invoice',
          data: {
            ...this.formData,
            billIds
          }
        })
        if (!res) {
          return res
        }
        this.$message.success('开票成功!')
        this.$emit('input', false)
        this.$emit('success')
        return 'success'
      } catch (error) {
        return error
      }
    }
  },
  created () {
  }
}
</script>

<style lang="sass" scoped>
.form-box
  overflow-y: auto
  overflow-x: hidden
  padding: 8px
  width: 100%
  height: calc( 100% - 55px )
  .table-box
    width: 100%
    height: 500px
  .usewater-table-box
    width: 100%
    height: 300px
.button-box
  text-align: right
  padding: 8px
</style>
