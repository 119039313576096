<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/invoice/list"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList"
    :operationWidth="130"
    @rowDblClick="handleRowDblClick")
  InvoiceDrawer(
    v-model="showDrawer"
    @success="getData")
  InvoiceDetaiDrawer(
    v-model="showDetailDrawer"
    :data.sync="detailData")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import InvoiceDrawer from './components/InvoiceDrawer.vue'
import InvoiceDetaiDrawer from './components/InvoiceDetaiDrawer.vue'

export default {
  name: 'InvoiceQuery',
  components: {
    InvoiceDrawer,
    InvoiceDetaiDrawer
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'detail', url: '/billManage/invoiceQuery/detail' },
      { name: 'invoiceCancel', url: '/billManage/invoiceQuery/invoiceCancel' },
      { name: 'invoice', url: '/billManage/invoiceQuery/invoice' }
    ])
    return {
      showTable: false,
      // 表格筛选表单配置 用户名称/水厂/抄表员/账单产生日期/缴费日期
      filterFields: [{
        name: 'invoiceStatus',
        label: '发票状态',
        form: {
          tag: 'select',
          relation: 'invoiceStatus',
          clearable: true
        }
      }, {
        name: 'invoiceCode',
        label: '发票编号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'invoiceNo',
        label: '发票号码',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'invoiceTime',
        label: '开票日期',
        form: {
          tag: 'date',
          dateType: 'daterange'
        }
      }],
      // 表格列配置
      columns: [
        {
          name: 'invoiceStatus',
          label: '发票状态',
          minWidth: 80,
          relation: 'invoiceStatus',
          render: { type: 'select' }
        }, {
          name: 'invoiceCode',
          label: '发票编号',
          minWidth: 100
        }, {
          name: 'invoiceNo',
          label: '发票号码',
          minWidth: 100
        },
        {
          name: 'invoiceName',
          label: '开票名称',
          minWidth: 120
        }, {
          name: 'invoiceBank',
          label: '开票银行',
          minWidth: 100
        },
        {
          name: 'invoicePhone',
          label: '开票联系电话',
          minWidth: 120
        },
        {
          name: 'invoiceTax',
          label: '纳税人识别号',
          minWidth: 120
        },
        {
          name: 'invoiceBankAccount',
          label: '银行账号',
          minWidth: 170
        }, {
          name: 'address',
          label: '联系地址',
          minWidth: 150
        }, {
          name: 'invoiceTime',
          label: '开票时间',
          minWidth: 140,
          render: { type: 'datetime' }
        }, {
          name: 'deleteTime',
          label: '作废时间',
          minWidth: 140,
          render: { type: 'datetime' }
        },
        { name: 'orgName', label: '水厂', minWidth: 100 }
      ],
      toolbarButtonList: actionRights.invoice ? [{
        label: '开票',
        name: 'invoice',
        type: 'primary',
        func: () => {
          this.showDrawer = true
        }
      }] : [],
      operateButtonList: [{
        label: '发票作废',
        name: 'invoiceCancel',
        type: 'text',
        textType: 'danger',
        showabled: (row) => actionRights.invoiceCancel && row.invoiceStatus !== 'DELETE',
        func: this.handleInvoiceCancel
      }, {
        label: '详情',
        name: 'check',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: this.handleInvoiceDetail
      }],
      showDrawer: false,
      showDetailDrawer: false,
      detailData: {}
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    handleRowDblClick (row) {
      const detailButton = this.operateButtonList[0]
      detailButton.showabled && detailButton.func({ data: row })
    },
    renderRelation () {
      this.getRelations(['invoiceStatus'])
        .then(res => {
          renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    handleInvoiceCancel ({ data }) {
      return new Promise((resolve, reject) => {
        this.$confirm('是否确认作废', '提示')
          .then(() => {
            this.$httpDelete({
              url: `/invoice/cancel?billId=${data.billId}`
            })
              .then(res => {
                if (!res) {
                  reject(res)
                } else {
                  this.$message.success('作废成功')
                  this.getData()
                  resolve('success')
                }
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    handleInvoiceDetail ({ data }) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/invoice/get/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
            } else {
              this.detailData = res.data
              this.showDetailDrawer = true
              resolve('success')
            }
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  position: relative
  padding-right: 8px
</style>
