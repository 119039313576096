<template lang="pug">
el-drawer(
  title="详情"
  size="80%"
  :visible.sync="visible"
  modal
  append-to-body
  :before-close="handleClose"
  @open="handleDrawerOpen")
  .form-box.full-box(v-if="visible")
    .blue-header 用水户账单
    .table-box
      DataTable.full-box(
        ref="billTable"
        :serverSide="false"
        :loading="loading"
        :data="tableData"
        :showFilter="false"
        :hasPage="false"
        :hasFooter="false"
        :settingButtonList="[]"
        :filterFields="[]"
        :columns="columns")
    .blue-header 票据信息
    DataForm(
      ref="form"
      v-model="formData"
      label-width="120px"
      type="grid"
      :gridspanNum="2"
      gridFlow="row"
      :inlineGrid="true"
      :formFields="formFields")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'InvoiceDetaiDrawer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    visible: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      showForm: false,
      formData: {},
      // 账单
      tableData: [],
      // 表格列配置
      columns: [
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 100 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' },
        { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } }
      ],
      // 开票表单
      formFields: [{
        name: 'invoiceName',
        label: '开票名称',
        form: {
          tag: 'text'
        }
      }, {
        name: 'invoiceBank',
        label: '开票银行',
        form: {
          tag: 'text'
        }
      },
      {
        name: 'invoicePhone',
        label: '开票联系电话',
        form: {
          tag: 'text'
        }
      },
      {
        name: 'invoiceTax',
        label: '纳税人识别号',
        form: {
          tag: 'text'
        }
      },
      {
        name: 'invoiceBankAccount',
        label: '银行账号',
        form: {
          tag: 'text'
        }
      }, {
        name: 'invoiceCode',
        label: '发票编号',
        form: {
          tag: 'text'
        }
      }, {
        name: 'invoiceNo',
        label: '发票号码',
        form: {
          tag: 'text'
        }
      }, {
        name: 'address',
        label: '联系地址',
        form: {
          tag: 'text'
        }
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    handleDrawerOpen () {
      this.formData = this.data
      this.$nextTick(() => {
        this.updateBillTableData(this.data.bills)
      })
    },
    handleClose () {
      this.$emit('input', false)
      this.formData = {}
      this.tableData = []
      this.$emit('update:data', {})
    },
    updateBillTableData (data) {
      this.$set(this, 'tableData', data || [])
      this.$refs.billTable && this.$refs.billTable.updateAllTableData(data)
    }
  },
  created () {
  }
}
</script>

<style lang="sass" scoped>
.form-box
  overflow-y: auto
  overflow-x: hidden
  padding: 8px
  width: 100%
  .table-box
    width: 100%
    height: 500px
</style>
